footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    gap: 20px;

    span {
        color: var(--grayColor);
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 0.9rem;
    }

    i {
        color: var(--whiteColor);
        margin: 0 15px;
        font-size: 1.5rem;
        transition: 0.3s;
    }

    i:hover {
        text-shadow: 0 0 10px var(--whiteColor);
    }

    .footer-logo {
        color: var(--whiteColor);
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 700;
        font-size: 1.3rem;
    }
}

/*-----------------------------------*\
 * # REPONSIVE
\*-----------------------------------*/

@media (max-width: 600px) {
    footer {
        flex-direction: column-reverse;
        align-items: center;
    }
}
