#process {
    width: 100%;
}

.process-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 100px;
    width: 100%;
    margin-top: 50px;

    .process-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;

        strong {
            font-size: 1.5rem;
        }

        ul {
            margin-top: 20px;

            li {
                font-size: 1.1rem;
                margin: 8px 0;
            }
        }

        span {
            color: rgb(41, 109, 225, 0.2);
            position: absolute;
            left: -15px;
            top: -30px;
            font-size: 4rem;
            z-index: -1;
        }
    }
}

/*-----------------------------------*\
 * # REPONSIVE
\*-----------------------------------*/

@media (max-width: 760px) {
    .process-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 550px) {
    .process-container {
        grid-template-columns: 1fr;
    }
}
