#languages {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    position: relative;
}

.languages-box {
    h3 {
        font-size: 1.3rem;
        letter-spacing: 1px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        background: rgb(0, 0, 0, 0.08);
        padding: 10px 15px;
        margin-top: 10px;

        li {
            color: var(--grayColor);
            margin-right: 15px;
            margin-top: 4px;
        }
    }
}

/*-----------------------------------*\
 * # REPONSIVE
\*-----------------------------------*/

@media (max-width: 660px) {
    #languages {
        grid-template-columns: 1fr;
    }
}
