#home {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    position: relative;
}

.home-text {
    width: 100%;
    max-width: 500px;

    strong {
        font-weight: 700;
        letter-spacing: 1px;
        font-size: 1.65rem;
        margin-bottom: 8px;
    }

    h1 {
        font-size: 2.8rem;
        line-height: 2.8rem;
        letter-spacing: 1px;
    }

    .home-name {
        background-color: #4158d0;
        background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        padding-bottom: 5px;
    }

    p {
        color: var(--grayColor);
        margin: 20px 0;
        letter-spacing: 1px;
        font-size: 1rem;
    }
}

.home-img {
    min-width: 330px;
    width: 330px;
    height: 330px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    background: rgb(0, 0, 0, 0.2);
    position: relative;

    .img-box {
        position: absolute;
        inset: 30px;
        border: 6px solid rgb(0, 0, 0, 0.2);
        z-index: 3;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: inline-block;
            object-fit: cover;
            object-position: top;
            transition: 0.5s;
            pointer-events: none;
            z-index: 5;
        }

        h2 {
            position: relative;
            color: #fff;
            font-size: 1.4rem;
            text-align: center;
            font-weight: 600;
            letter-spacing: 0.05rem;
            text-transform: uppercase;

            span {
                font-weight: 300;
                font-size: 0.9rem;
            }
        }

        .social {
            a {
                color: #fff;
                font-size: 1.5rem;
                margin: 0 7px;
                transition: 0.3s;
            }

            a:hover {
                text-shadow: 0 0 10px #fff;
            }
        }

        .hire-me {
            position: relative;
            margin-top: 10px;
            padding: 6px 20px;
            background: #fff;
            color: var(--blackColor);
            border-radius: 25px;
            font-weight: 500;
            font-size: 1.2rem;
            text-transform: uppercase;
            letter-spacing: 0.05rem;
            transition: 0.5s;
        }

        .hire-me:hover {
            background: var(--grayColor);
            text-shadow: 0 0 10px #fff;
        }
    }
}

.home-img::before {
    content: '';
    position: absolute;
    inset: -10px 140px;
    background: linear-gradient(315deg, #00ccff, #d400d4);
    transition: 0.5s;
    animation: animate 4s linear infinite;
}

.home-img:hover::before {
    inset: -20px 0;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    0% {
        transform: rotate(360deg);
    }
}

.home-img::after {
    content: '';
    position: absolute;
    inset: 6px;
    background: rgb(22, 32, 82);
    border-radius: 50%;
    z-index: 1;
}

.home-img:hover .img-box img {
    opacity: 0;
}

/*-----------------------------------*\
 * # REPONSIVE
\*-----------------------------------*/

@media (max-width: 865px) {
    #home {
        justify-content: center;
    }

    .home-text {
        max-width: none;
    }
}
