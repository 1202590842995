@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --bgColor: #131111;
    --whiteColor: #fff;
    --grayColor: #bbb;
    --blueColor: #689af8;
    --blackColor: #000;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
}

body {
    width: 100%;
    position: relative;
    background-color: var(--bgColor);
    color: var(--whiteColor);
}

section {
    margin-top: 30px;
    margin-bottom: 100px;
}

ul {
    list-style: none;
}

h3 {
    font-size: 3rem;
    letter-spacing: 2px;
}

::-webkit-scrollbar {
    display: none;
}

.container {
    width: 100%;
    max-width: 1024px;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 15px;
    overflow: hidden;
}

.btn-link {
    color: var(--whiteColor);
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 5px;
    font-size: 0.8rem;
    border-bottom: var(--blueColor);
    padding: 10px 10px 10px 0;
    transition: all 0.5 cubic-bezier(0.075, 0.82, 0.165, 1);
    cursor: pointer;
}

.btn-link:hover {
    padding: 10px;
    background: rgb(104, 154, 248, 0.102);
}
