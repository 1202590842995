.career-education {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 200px;
}

.career-education::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 1px;
    background: var(--grayColor);
}

.career h3,
h3 {
    font-size: 3.1rem;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.c-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;

    h4 {
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
        border-left: 3px solid var(--blueColor);
        line-height: 1.3rem;
        padding-left: 10px;
    }

    strong {
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding-left: 10px;
    }

    span {
        color: var(--grayColor);
        font-size: 1rem;
        letter-spacing: 2px;
        padding-left: 10px;
        margin-top: 6px;
    }
}

/*-----------------------------------*\
 * # REPONSIVE
\*-----------------------------------*/

@media (max-width: 1024px) {
    .career-education {
        
        gap: 50px;
    }
}

@media (max-width: 900px) {
    .career-education {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
    }

    .career-education::before {
        display: none;
    }

    .career,
    .career-edu {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .career-edu h3 {
        display: none;
    }

    h3 {
        text-align: center;
    }
}
