@import url('https://fonts.googleapis.com/css2?family=Merienda+One&display=swap');

.navigation {
    width: 100%;
    height: 70px;
    background-color: var(--bgColor);
    display: flex;
    align-items: center;

    .logo {
        font-family: 'Merienda One', cursive;
        color: var(--whiteColor);
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
    }
}
